import { graphql } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"

import { Container, Modal } from "react-bootstrap"
import FormFields from "../../static/forms/book_a_viewing.json"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import PropertyBanner from "../components/PropertyBanner/PropertyBanner"
import PropertyInformation from "../components/PropertyInformation/PropertyInformation"
import SimilarProperties from "../components/SimilarProperties/SimilarProperties"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import DefaultForm from "../components/forms/default-form-module"
import Seo from "../components/seo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import useLocation from "../hooks/useLocation"

const PropertyDetails = props => {
  const PageData = props?.data?.allProperties?.edges[0]?.node
  const negotiatorData = props?.data?.strapiTeam
//   //   console.log(negotiatorData)
  const [shareIcons, setShareIcons] = useState(false)
  const [show, setShow] = useState(false)

  const { isMobile, isLargeScreen } = useDeviceMedia()

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
  }

  const shareurl = typeof window !== "undefined" ? window.location.href : ""

  const negoImage = negotiatorData?.image?.url

  const congifdata =
    props?.data?.allStrapiSiteConfig?.edges?.length > 0
      ? props?.data?.allStrapiSiteConfig?.edges[0]
      : null

  const phoneNum = negotiatorData?.phone
    ? negotiatorData?.phone
    : congifdata?.node?.head_office_number

  const popUpData =
    props?.data?.allStrapiSiteConfig?.edges?.length > 0
      ? props?.data?.allStrapiSiteConfig?.edges[0]?.node?.properties_popup
      : ""

  const image_url = negoImage ? negoImage : congifdata?.node?.office_image?.url

  const name = negotiatorData?.name
    ? negotiatorData?.name
    : congifdata?.node?.office_name

  const dynamicData = {
    area: PageData?.area,
    department: PageData?.department,
    searchType: PageData?.search_type,
  }

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const propImage = PageData?.images?.length > 0 ? PageData?.images[0]?.url : ""
  return (
    
    <Layout
      popUpData={popUpData}
      dynamicData={dynamicData}
      dark="dark"
      footerClass="property-footer"
    >
      {/* <Head /> */}
      <div className="header-top"></div>
      <Container>
        <div className="breadcum-share-wrapper">
          <BreadcrumbModule
            department={PageData?.department}
            alias={PageData?.search_type}
            type="property-details-page"
            pagename={PageData?.display_address}
          />
          <div
            className="share-property-details"
            onClick={() => openShareicons()}
          >
            <i className="icon grey-share-icon" />
            <span>{isMobile ? "Share" : "Share Property"}</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={shareurl}
              />
            )}
          </div>
        </div>
      </Container>
      <PropertyBanner
        openGallery={props?.location?.state?.openGallery}
        PageData={PageData}
      />
      <PropertyInformation
        PageData={PageData}
        phoneNum={phoneNum}
        negotiator={negotiatorData}
        negoImage={negoImage}
        name={name}
        image_url={image_url}
      />
      <SimilarProperties {...PageData} />
      {isLargeScreen && (
        <div className="property-footer-ctas">
          {phoneNum && (
            <a
              className="whats-app"
              target="_blank"
              href={`https://wa.me/${phoneNum?.replace(
                /[^A-Z0-9]/gi,
                ""
              )}?text=`}
            >
              <button className="button button-filled-green">
                {" "}
                <i className="icon black-whatsapp" />
                {!isMobile && <span>whatsapp</span>}
              </button>
            </a>
          )}

          <a className="e_mail" onClick={handleModal}>
            <button className="button button-outline-white">
              {" "}
              <i className="icon black-mail" /> <span>Email</span>
            </button>
          </a>
          {
            <Modal
              show={show}
              // onHide={handleClose}
              backdrop="static"
              centered
              dialogClassName="modal-popup-form"
            >
              <Modal.Body>
                <div className="popup-form-wrapper">
                  <div className="close-modal" onClick={() => handleClose()}>
                    <i className="icon black-cancel-icon"></i>
                  </div>
                  <div>
                    <DefaultForm
                      fields={FormFields}
                      sourceUrl={pageurl}
                      prop_img_url={propImage}
                      prop_address={PageData?.display_address}
                      formTitle="Book a Viewing"
                    />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          }

          {phoneNum && (
            <a className="call_num" href={`tel:${phoneNum}`}>
              <button className="button button-outline-white">
                {" "}
                <i className="icon black-call-icon" /> <span>Call</span>
              </button>
            </a>
          )}
        </div>
      )}
    </Layout>
  )
}

export const Head = props => {
  const pageData = props?.data?.allProperties?.edges[0]?.node
  //seo title, h1 and desc prepare, this can send to utilis function later
  let desc_meta_ptype =
    pageData?.building?.length > 0
      ? pageData?.building?.join(" and ")
      : "property"
  let department = pageData?.department
  let commercialType =
    pageData?.building?.length > 0
      ? pageData?.building?.join(" and ")
      : "properties"
  let cap_ptype =
    desc_meta_ptype?.charAt(0).toUpperCase() + desc_meta_ptype?.slice(1)
  let desc_meta_seachtype = `for sale`
  if (pageData?.search_type == "lettings") {
    desc_meta_seachtype = `to rent`
  }
  let desc_meta_beds = pageData?.bedroom
  let desc_meta_address = pageData?.display_address
  let desc_meta_price = `AED` + pageData?.price?.toLocaleString()
  let pagetitle =
    department === "commercial"
      ? `Commercial ${commercialType} ${desc_meta_seachtype} in ${desc_meta_address}`
      : `${cap_ptype} ${desc_meta_seachtype} with ${desc_meta_beds} bedrooms in ${desc_meta_address} at ${desc_meta_price}`
  let pagemetadesc =
    department === "commercial"
      ? `Book a viewing for this commercial ${commercialType} ${desc_meta_seachtype} in ${desc_meta_address}. Contact our properties specialists for more information about the properties.`
      : `Know the details of ${desc_meta_ptype} ${desc_meta_seachtype} with ${desc_meta_beds} bedrooms in ${desc_meta_address} at ${desc_meta_price}. Book a viewing with ${process.env.GATSBY_SITE_NAME} to get assistance in finding the right ${desc_meta_ptype} for you.`
  let pageUrl = typeof window !== "undefined" ? window.location.href : ""
  // +
  // (props?.location?.pathname).replace(/\/?$/, "/")
  let myimgtransforms = ""
  if (pageData?.imagetransforms?.images_Transforms) {
    myimgtransforms = JSON.parse(pageData?.imagetransforms?.images_Transforms)
  }
  let pageImg = ""
  if (myimgtransforms && Object.keys(myimgtransforms).length > 0) {
    let mymetaimg = Object.values(myimgtransforms)
    let pageImg_filt = []
    for (const myimgtransform in myimgtransforms) {
      if (typeof myimgtransforms[myimgtransform]["webp"] !== "undefined") {
        pageImg_filt = Object.values(myimgtransforms[myimgtransform]["webp"])
        break
      }
    }
    if (pageImg_filt.length > 0) pageImg = pageImg_filt[0]
  }
  var ldJson = {
    "@context": "https://schema.org",
    "@type": "SingleFamilyResidence",
    name: pageData?.title,
    description: pagemetadesc,
    numberOfRooms: pageData?.bedroom,
    occupancy: {
      "@type": "QuantitativeValue",
      minValue: 1,
      maxValue: 5,
    },
    floorSize: {
      "@type": "QuantitativeValue",
      value: pageData?.floorarea_min,
      unitCode: "squareFeet",
    },
    numberOfBathroomsTotal: pageData?.bathroom,
    numberOfBedrooms: pageData?.bedroom,
    address: {
      "@type": "PostalAddress",
      addressCountry: pageData?.address?.address3,
      addressLocality: pageData?.address?.address1,
      addressRegion: pageData?.address?.address2,
      postalCode: pageData?.address?.postcode,
      streetAddress: pageData?.display_address,
    },
    telephone: "+971 4 302 5800",
  }
  var { pathname } = useLocation()
  var curentUrl = ""
  curentUrl = process.env.GATSBY_SITE_URL + pathname
  // check trailing slash
  if (!curentUrl.endsWith("/")) curentUrl = curentUrl + "/"
  // remove page- from url
  if (curentUrl.includes("page-")) {
    curentUrl = curentUrl
      .split("/")
      .filter(path => !path.includes("page-"))
      .join("/")
  }
  //remove multiple slashes
  curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1")
  //set lowercase url
  curentUrl = curentUrl.toLowerCase()
  return (
    <Seo title={pagetitle} description={pagemetadesc} imageUrl={pageImg}>
      <meta property="og:url" content={curentUrl} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
      />
    </Seo>
  )
}

export const query = graphql`
  query ($strapi_id: Int, $negotiator_id: String) {
    strapiTeam(negotiator_id: { eq: $negotiator_id }) {
      designation
      email
      image {
        url
      }
      tile_image {
        url
      }
      imagetransforms {
        image_Transforms
      }
      name
      phone
      slug
      whatsapp
      strapi_id
      negotiator_id
    }
    allProperties(filter: { strapi_id: { eq: $strapi_id } }) {
      edges {
        node {
          title
          negotiator_id
          crm_id
          area
          department
          latitude
          longitude
          status
          price
          bedroom
          display_address
          accommodation_summary
          long_description
          description
          bathroom
          imagetransforms {
            images_Transforms
          }
          images {
            url
          }
          video_tour {
            url
          }
          qr_code {
            validationQr
            listingNumber
          }
          brochure {
            srcUrl
          }
          virtual_tour {
            url
          }
          search_type
          strapi_id
          floorarea_type
          floorarea_min
          crm_negotiator_id {
            email
            job_title
            mobile_phone
            name
            profile_img
            work_phone
          }
          community
          special
        }
      }
    }
    allStrapiSiteConfig {
      edges {
        node {
          office_name
          office_image {
            url
          }
          head_office_number
          properties_popup {
            time_count
            title
            show_popup
            image {
              url
            }
            cta {
              custom_link
              link_type
              title
              link {
                slug
                strapi_parent {
                  slug
                }
              }
            }
            content {
              data {
                content
              }
            }
          }
        }
      }
    }
  }
`

export default PropertyDetails
