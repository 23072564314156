import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import {
  calculateMonthlyPayment,
  filterNumber,
  formTracking,
  numberFormat,
  numericWithDecimal,
} from "../Common/utils"
import "./MortgageCalculator.scss"

const MortgageCalculator = props => {
  const propertyData = props?.propertyInfo

  const [propertyPrice, setPropertyPrice] = useState(
    numberFormat(
      props.landingPage
        ? filterNumber("1,000,000")
        : propertyData?.price
        ? propertyData?.price
        : ""
    )
  )
  const [deposit, setDeposit] = useState(
    numberFormat(Math.round(filterNumber(propertyPrice) * 0.2))
  )
  const [mortgagePeriod, setMortgagePeriod] = useState(25)
  const [intrestRate, setIntrestRate] = useState(props.landingPage ? 5 : 5)
  const [periodErr, setPeriodErr] = useState(false)
  const [depositErr, setDepositErr] = useState(false)
  const [intrestErr, setIntrestErr] = useState(false)

  const handleChange = event => {
    if (event.target.name === "property_price") {
      let val = filterNumber(event.target.value)
      setPropertyPrice(numberFormat(val))
      setDeposit(numberFormat(Math.round(val * 0.2)))
    } else if (event.target.name === "mortgage_period") {
      let duration = filterNumber(event.target.value)
      if (duration > 30 || duration < 5) {
        setPeriodErr("Mortgage period should be between 5 to 30")
        setMortgagePeriod(numberFormat(duration))
      } else {
        setMortgagePeriod(numberFormat(duration))
        setPeriodErr(false)
      }
    } else if (event.target.name === "deposit") {
      let dep = filterNumber(event.target.value)
      if (dep >= filterNumber(propertyPrice)) {
        setDepositErr(
          "Deposit should be less than property price and greater than zero"
        )
        return
      } else {
        setDepositErr(false)
        setDeposit(numberFormat(dep))
      }
    } else if (event.target.name === "intrest_rate") {
      let intrest = numericWithDecimal(event.target.value)
      if (intrest > 10 || intrest < 1) {
        setIntrestErr("Intrest Rate should be between 1% to 10%")
        setIntrestRate(intrest)
      } else {
        setIntrestRate(intrest)
        setIntrestErr(false)
      }
    }
  }

  // const loanAmount = filterNumber(propertyPrice) - filterNumber(deposit)
  const repayments = calculateMonthlyPayment(
    filterNumber(propertyPrice),
    numericWithDecimal(parseFloat(intrestRate)),
    filterNumber(deposit),
    filterNumber(mortgagePeriod)
  )

  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem(
        "mortgage_repayments",
        numberFormat(Math.round(repayments))
      )
    }
    formTracking({
      event_tracking: "Mortage calculator",
      form_name: "Mortage calculator",
    })
  }, [repayments])

  return (
    <div className="mortage-calc" id="mortgage-calculator">
      <div className="property-horizontal-line"></div>
      <h2 className="mortgage-title">Mortgage Calculator</h2>
      <p className="sub-title-mortgage">
        Estimate your monthly mortgage payments
      </p>
      <div className="mortgage-calc-form">
        <Form className="mortgage-form-section">
          <Form.Group controlId="formFile" className="form-text-field">
            <Form.Label>Property Price</Form.Label>
            <Form.Control
              type="text"
              name="property_price"
              value={propertyPrice}
              onChange={e => handleChange(e)}
            />
            {intrestErr || periodErr || depositErr ? (
              ""
            ) : (
              <span className="price-name">AED</span>
            )}
          </Form.Group>
          <Form.Group controlId="formFile" className="form-text-field">
            <Form.Label>Deposit</Form.Label>
            <Form.Control
              type="text"
              name="deposit"
              value={deposit}
              onChange={e => handleChange(e)}
            />
            {intrestErr || periodErr || depositErr ? (
              ""
            ) : (
              <span className="price-name">AED</span>
            )}
            {depositErr && <p className="mortgage-err-msg">{depositErr}</p>}
          </Form.Group>
          <Form.Group controlId="formFile" className="form-text-field">
            <Form.Label>Mortgage Period</Form.Label>
            <Form.Control
              type="text"
              name="mortgage_period"
              value={mortgagePeriod}
              onChange={e => handleChange(e)}
            />
            {intrestErr || periodErr || depositErr ? (
              ""
            ) : (
              <span className="price-name">Years</span>
            )}
            {periodErr && <p className="mortgage-err-msg">{periodErr}</p>}
          </Form.Group>

          <Form.Group controlId="formFile" className="form-text-field">
            <Form.Label>Interest Rate</Form.Label>
            <Form.Control
              type="text"
              name="intrest_rate"
              value={numericWithDecimal(intrestRate)}
              onChange={e => handleChange(e)}
            />
            {intrestErr || periodErr || depositErr ? (
              ""
            ) : (
              <span className="price-name">%</span>
            )}
            {intrestErr && <p className="mortgage-err-msg">{intrestErr}</p>}
          </Form.Group>
        </Form>
      </div>
      <div className="mortgage-repayments">
        <div className="repayment-result">
          <p className="repayments-month">Monthly Payment</p>
          <h3>AED {numberFormat(Math.round(repayments))}</h3>
          {!props.landingPage && (
            <Link to="/other-services/mortgages/" className="mortgage-fees">
              View Mortgage Costs and Fees
            </Link>
          )}
        </div>
        {!props.landingPage && (
          <div className="preapprove-block">
            <Link to="/other-services/mortgages/" className="mortgage-fees">
              <button className="button button-filled-green">
                Get pre-approved
              </button>
            </Link>
            <div className="whats-app-block">
              <a
                className="whatsapp-link"
                href="https://wa.me/97143025800?text=Hi, I've come across the haus and haus Group and would like further information from you"
                target="_blank"
              >
                WhatsApp our team on <span>+97143025800</span>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MortgageCalculator
