import React, { useEffect, useState } from "react"
import { ApiRequest } from "../Common/api_request_utils"
import CustomSlider from "../CustomSlider/CustomSlider"
import { Container } from "react-bootstrap"
import "./SimilarProperties.scss"
import PropertySliderCard from "../PropertySliderCard/PropertySliderCard"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const SimilarProperties = props => {
  const [propItems, setPropItems] = useState([])
  const statusVal =
    props.status === "For Sale" || props.status === "Under Offer"
      ? "similar-properties-sale"
      : props.status === "Sold"
      ? "similar-properties-sold"
      : props.status === "Let"
      ? "similar-properties-let"
      : ""

  useEffect(() => {
    if (props?.strapi_id) {
      ApiRequest(
        {
          method: "GET",
          url: `${process.env.GATSBY_STRAPI_SRC}/api/stb-lists/item/${statusVal}?pid=${props?.strapi_id}`,
        },
        result => {
          if (result.length > 0) {
            setPropItems(result)
          }
        }
      )
    }
  }, [])


  const {isMobile}=useDeviceMedia()


  if(!propItems.length > 0){
    return
  }

  return (

    <div className="similar-properties-wrapper">
      <Container>
        <h2 className="title">{isMobile?"More properties...":"Other properties that may interest you"}</h2>
        <CustomSlider className="similar-prop-slider" slidecount={3}>
          {propItems.length > 0 &&
            propItems.map((item, index) => {
              const parseImage = item?.images && JSON.parse(item.images)
              const imageUrl = parseImage?.length > 0 && parseImage[0]
              const imageLength=parseImage?.length 

              return (

                <PropertySliderCard
                  title={item.title}
                  image={imageUrl}
                  imageCount={imageLength}
                  imageList={parseImage}
                  display_address={item.display_address}
                  price={item.price}
                  bathroom={item.bathroom}
                  bedroom={item.bedroom}
                  floorarea_min={item.floorarea_min}
                  floorarea_type={item.floorarea_type}
                  search_type={item.search_type}
                  department={item.department}
                  strapi_id={item.id}
                  slug={item.slug}
                  crm_id={item.crm_id}
                  status={item.status}
                  imagetransforms={item.imagetransforms}
                  index={index}
                />
              )
            })}
        </CustomSlider>
      </Container>
    </div>
  )
}

export default SimilarProperties
