import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import "./PropertyAreaCard.scss"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import ScrollAnimation from "react-animate-on-scroll"

const PropertyAreaCards = props => {
  // const [isArea,setIsArea]=useState(false)
  const cardData = useStaticQuery(graphql`
  query {
    allStrapiArea{
      edges {
        node {
          title
          slug
          publish
        }
      }
    }
    
    allStrapiAreaGuide {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`)



  const areaData = props.propertyInfo
  const { isLargeScreen } = useDeviceMedia()
  const searchtypeText=props.propertyInfo.search_type==="sales"?"buying":"letting" 

  const splitSlug=areaData?.community?.split(" ")
  let marketSlug=splitSlug?.join("-")?.toLowerCase()

  let areaList=cardData?.allStrapiArea?.edges;
 const findArea= areaList?.filter(list=>list.node.slug===marketSlug)

 let areaGuideList=cardData?.allStrapiAreaGuide?.edges;
 const findAreaGuide=areaGuideList?.filter(list=>list.node.slug===marketSlug)

  return (
    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
    <div className="property-area-card">
      <h5>Useful to know</h5>
      <div className="card-wrapper">
        {findAreaGuide?.length>0&&
        <div className="card-data card1">
          <icon className="icon green-area-icon" />
          <h5 className="area-name">{areaData?.community}</h5>
          <p>Find out everything you need to know the local area.</p>
          <Link to={`/guides/area-guides-dubai/${marketSlug}/`}>
            <span className="link-text">Explore</span>
            <span className="icon-holder">
              <i className="icon arrow-right-icon" />
            </span>
          </Link>
        </div>
        }

      {findArea?.length>0&&
        <div className="card-data card2">
          <icon className="icon green-market-icon" />
          <h5 className="area-name">Market Report</h5>
          <p>Insight into the property market in {areaData?.community}.</p>
          <Link to={`/resources/market-reports?community=${marketSlug}`}>
            <span className="link-text">Find out more</span>
            <span className="icon-holder">
              <i className="icon arrow-right-icon" />
            </span>
          </Link>
        </div>
      }

        <div className="card-data card3">
          <icon className="icon green-buyers-icon" />
          <h5 className="area-name">{props.propertyInfo.search_type==="sales"?"Buyers Guide":"Tenants Guide"}</h5>
          <p>We explain every step of {searchtypeText} a property in Dubai.</p>
          <Link to={`${props.propertyInfo.search_type==="sales"?"/guides/buyers-guide/":"/guides/tenants-guide/"}`}>
            <span className="link-text">Find out more</span>
            <span className="icon-holder">
              <i className="icon arrow-right-icon" />
            </span>
          </Link>
        </div>
      </div>

      {isLargeScreen && (
        <div className="property-valuation-card">
          <div className="val-card-wrapper">
            <h5>Get an instant property valuation in under 2 minutes.</h5>
            <p>
              Suspendisse placerat nunc in velit tristique, vel pretium ante
              lacinia.
            </p>
            <Link to="/property-valuation/">
            <button className="button button-outline-black">
              Get your valuation
            </button>
            </Link>
          </div>
        </div>
      )}
    </div>
    </ScrollAnimation>
  )
}

export default PropertyAreaCards
