import { Link } from "gatsby"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/book_a_viewing.json"
import NoImage from "../../images/no-image.png"
import DefaultForm from "../forms/default-form-module"
import "./PropertyContactModule.scss"



const PropertyContactModule = props => {

  const [show, setShow] = useState(false)

  const negotiator=props.negotiator
  const negoImage=props.negoImage

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const propImage=props.propertyInfo?.images?.length>0?props.propertyInfo?.images[0]?.url:""
  const text=`Hi, I've come across the haus and haus Group and would like further information from you`

  return (
    <div className="property-contact-component">
      <div className="property-contact-content">
        
        <div className="property-team-desktop">
          <div className="cta-holder">
            {props.phoneNum&&
            <a className="whatsapp-link" target="_blank" href={`https://wa.me/${props.phoneNum?.replace(/[^A-Z0-9]/ig, "")}?text=${text}`}>
              <button className="button button-filled-green">
                {" "}
                <i className="icon black-whatsapp" />
                <span>whatsapp agent</span>
              </button>
            </a>
            }
            
            <div className="cta-secondary">
            {props.propertyInfo?.status!=="Sold"&&
              <a onClick={handleModal}>
                <button className="button button-outline-white">
                  {" "}
                  <i className="icon black-mail" /> <span>Email</span>
                </button>
              </a>
            }
              {
                 <Modal
                 show={show}
                 // onHide={handleClose}
                 backdrop="static"
                 centered
                 dialogClassName="modal-popup-form"
               >
                 <Modal.Body>
                   <div className="popup-form-wrapper">
                     <div className="close-modal" onClick={() => handleClose()}>
                       <i className="icon black-cancel-icon"></i>
                     </div>
                     <div>
                       <DefaultForm 
                       fields={FormFields} 
                       sourceUrl={pageurl}
                       prop_img_url={propImage} 
                       negoEmail={negotiator?.email}
                       prop_address={props.propertyInfo?.display_address} 
                       formTitle="Book a Viewing"/>
                     </div>
                   </div>
                 </Modal.Body>
               </Modal>
              }
              {props.phoneNum&&
              <a href={`tel:${props.phoneNum}`}>
                <button className="button button-outline-white">
                  {" "}
                  <i className="icon black-mobile" /> <span>Call</span>
                </button>
              </a>
              }
            </div>
          </div>
          {props.image_url&&
          <div className="property-team-data">
            <div className="image-section">
              {props.image_url?
              <img src={props.image_url} alt="negotiator" />
              :
              <img src={NoImage} alt="negotiator" />
              }
            </div>
            <div className="property-team-info">
              <h5 className="name">
                {props.name}
              </h5>
              {negotiator?.designation&&
              <p className="designation">{negotiator?.designation}</p>
              }
              {negotiator?.language_speaks&&
               <p className="language-speak">Speaks {negotiator?.language_speaks}</p>
              }
            </div>
            
          </div>
          }
        </div>
        <div className="property-val-desktop">
          <div className="val-card-wrapper">
            <h5>Get your instant property valuation</h5>
            <p>
            Get an approximate valuation in a matter of minutes.
            </p>
            <Link to="/property-valuation/">
            <button className="button button-outline-black">
              Get your valuation
            </button>
            </Link>
          </div>
        </div>
      </div>
     
    </div>
  )
}

export default PropertyContactModule
