import { Link } from "gatsby"
import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { numberFormat } from "../Common/utils"
import "./PropertySliderCard.scss"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const PropertySliderCard = ({
  image,
  price,
  display_address,
  slug,
  department,
  crm_id,
  index,
  floorarea_min,
  floorarea_type,
  title,
  strapi_id,
  search_type,
  status,
  bathroom,
  imagetransforms,
  bedroom,
  slider_name,
  imageCount,
  imageList,
}) => {
  const id = crm_id
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  let url = ""
  if (search_type === "sales" && department === "residential") {
    url = "/properties-for-sale/"
  } else if (search_type === "lettings" && department === "residential") {
    url = "/properties-to-rent/"
  } else if (search_type === "sales" && department === "new_developments") {
    url = "/new-homes/for-sale/"
  } else if (department === "commercial") {
    url = "/commercial-properties-for-sale/"
  }
  let floorarea

  if (floorarea_type === "squareFeet" && floorarea_min !== 0 && floorarea_min) {
    floorarea = Math.floor(floorarea_min / 10.764)
  }

  let processedImages
  if (slider_name === "team-property-slider") {
    processedImages = imagetransforms?.images_Transforms || JSON.stringify({})
  } else {
    processedImages =
      JSON.parse(imagetransforms)?.images_Transforms || JSON.stringify({})
  }

  var propertyLightImages =
    imageList?.length > 0 &&
    imageList.map(img => (img.srcUrl ? img.srcUrl : img.url))

  const openPropertyImage = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }

  const imagename = "property.images.tileimg"

  const address = `${title} in ${display_address}`

  return (
    <ScrollAnimation
      animateIn="animate__slideInUp"
      animateOnce
      delay={index * 100}
      offset={100}
    >
      <div className="propertyslider-card-section">
        <div className="image-section img-zoom">
          <Link to={`${url}${slug}/${strapi_id}/`}>
            <ImageModule
              ImageSrc={image}
              altText={`${title}`}
              imagetransforms={processedImages}
              renderer="pic-src"
              imagename={imagename}
              strapi_id={strapi_id}
              classNames="property-card_image"
            />
            {(status==="Let" || status==="Sold") &&
                <div className="status-flag">
                  <p>{status}</p>
                </div>
                }
            <div className="img_count-section">
              <a
                href="javascript:void(0)"
                onClick={e => openPropertyImage(e, 0)}
              >
                <i className="icon gallery-black-icon"></i>{" "}
                <span>{imageCount}</span>
              </a>
            </div>
          </Link>
        </div>

        <div className="content">
          <p className="price">{`AED ${numberFormat(price)}`} </p>
          <Link className="title" to={`${url}${slug}/${strapi_id}/`}>
            {address}
          </Link>
          {/* <Link to={`${url}${slug}-${crm_id}/`}>
          <p className="address">{display_address}</p>
        </Link> */}
          <div className="rooms-count">
            {bedroom !== 0 && (
              <div className="bedrooms">
                <i className="icon bed-icon-grey" /> <span>{bedroom}</span>
              </div>
            )}
            {bathroom !== 0 && (
              <div className="bathrooms">
                <i className="icon bath-icon-grey" /> <span>{bathroom}</span>
              </div>
            )}
            {floorarea_min && floorarea_type === "squareFeet" && (
              <div className="area">
                <i className="icon area-icon-grey" />{" "}
                <span>{numberFormat(floorarea_min)} sq.ft</span>
              </div>
            )}
          </div>
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={propertyLightImages[photoIndex]}
            nextSrc={
              propertyLightImages[(photoIndex + 1) % propertyLightImages.length]
            }
            prevSrc={
              propertyLightImages[
                (photoIndex + propertyLightImages.length - 1) %
                  propertyLightImages.length
              ]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + propertyLightImages.length - 1) %
                  propertyLightImages.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
            }
          />
        )}
      </div>
    </ScrollAnimation>
  )
}

export default PropertySliderCard
