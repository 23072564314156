import React, { useState } from "react"
import "./PropertyBanner.scss"
import { Container } from "react-bootstrap"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import PlayVideo from "../PlayVideo/PlayVideo"
import { formTracking } from "../Common/utils"
import NoImage from "../../images/no-image.png"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const PropertyBanner = props => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(props?.openGallery ? true : false)
  const [propertyVideo, setPropertyVideo] = useState(false)

  const bannerData = props.PageData
  // const largeImage = bannerData?.images?.strapi_json_value.length>0?bannerData?.images?.strapi_json_value[0]?.url:""
  const largeImage =
    bannerData?.images?.length > 0
      ? bannerData?.images[0]
      : ""

  const smallImage =
    bannerData?.images?.length > 0
      ? bannerData?.images?.slice(1, 3)
      : ""

  const imageCount = bannerData?.images?.length

  const showMapComponent = () => {
    const getId = document.getElementById("map-component")
    getId.scrollIntoView({ behavior: "smooth" })
  }

  const propertyImages = bannerData?.images;
  var propertyLightImages =
    propertyImages &&
    propertyImages.map(img => (img.srcUrl ? img.srcUrl : img?.url))

  const openPropertyImage = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }

  const { isLargeScreen } = useDeviceMedia()

  let propertyVideoUrl = ""

  if (bannerData?.video_tour?.length) {
    propertyVideoUrl = bannerData?.video_tour[0]?.url
  }

  let processedImages = JSON.stringify({})
  if (bannerData?.imagetransforms) {
    processedImages = bannerData?.imagetransforms?.images_Transforms
  }

  const imagename = "property.images.details"

  const handleBrochure=()=>{
    formTracking({
      event_tracking: "Property Detail - Download Brochure",
      form_name: "Property Detail - Download Brochure",
    })
  }

  const handleVideo=()=>{
    formTracking({
      event_tracking: "Video Click",
      form_name: "Video Click",
    })
    setPropertyVideo(!propertyVideo)
  }

  return (
    <>
      <div className="property-banner-wrapper">
        <Container>
          <div className="property-image-sections">
            <div className="large-image img-zoom">
              <a
                href="javascript:void(0)"
                onClick={e => openPropertyImage(e, 0)}
              >
                {largeImage?.url?
                <ImageModule
                  ImageSrc={largeImage}
                  altText={`${bannerData?.title}`}
                  imagetransforms={processedImages}
                  renderer="pic-src"
                  imagename={imagename}
                  strapi_id={props?.strapi_id}
                  classNames="property-card_image"
                />
                :
                <img src={NoImage}  classNames="property-card_image" alt="No Image" />
                }

              {(bannerData?.status==="Let" || bannerData?.status==="Sold") &&
                <div className="status-flag">
                  <p>{bannerData?.status}</p>
                </div>
                }

                {/* <img src={largeImage} alt={bannerData.title} /> */}
              </a>
              <div className="property-image-containers">
                {bannerData?.images &&
                  bannerData?.images?.length > 0 && (
                    <div className="view-images-property icon-text">
                      <a
                        href="javascript:void(0)"
                        onClick={e => openPropertyImage(e, 0)}
                      >
                        <i className="icon gallery-black-icon"></i>{" "}
                        <span>1/{imageCount} Photos</span>
                      </a>
                    </div>
                  )}
                <div className="property-view-map icon-text">
                  <a
                    href="javascript:void(0)"
                    onClick={() => showMapComponent()}
                  >
                    <i className="icon map-black-icon" /> <span>Map</span>
                  </a>
                </div>
                {bannerData?.brochure?.length > 0 && (
                  <div className="brochure icon-text">
                    <a
                    onClick={handleBrochure}
                      href={
                        bannerData?.brochure[0]?.srcUrl
                      }
                      target="_blank"
                    >
                      <i className="icon brochure-black-icon" />{" "}
                      <span>Brochure</span>
                    </a>
                  </div>
                )}
              </div>
            </div>

            {!isLargeScreen && (
              <div className="small-image">
                {smallImage &&
                  smallImage.map((image, index) => (
                    <a
                      href="javascript:void(0)"
                      onClick={e => openPropertyImage(e, index + 1)}
                      className="img-zoom"
                    >
                      {image?.url?
                      <ImageModule
                        ImageSrc={image}
                        altText={`${bannerData?.title}`}
                        imagetransforms={processedImages}
                        renderer="pic-src"
                        imagename={imagename}
                        strapi_id={props?.strapi_id}
                        classNames="property-card_image"
                      />
                      :
                      <img src={NoImage} classNames="property-card_image" alt="No Image" />
                    }
                      {/* <img src={image.url} alt={bannerData.title} /> */}
                      {index === 0 &&
                        bannerData?.video_tour?.length >
                          0 && (
                          
                          <div className="play-btn" onClick={() =>handleVideo()}>
                            <i className="icon green-play-btn"/>
                            </div>
                        )}
                    </a>
                  ))}
              </div>
            )}
          </div>
        </Container>
      </div>
      {isOpen && propertyLightImages?.length>0&& (
        <Lightbox
          mainSrc={propertyLightImages[photoIndex]}
          nextSrc={
            propertyLightImages[(photoIndex + 1) % propertyLightImages.length]
          }
          prevSrc={
            propertyLightImages[
              (photoIndex + propertyLightImages.length - 1) %
                propertyLightImages.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + propertyLightImages.length - 1) %
                propertyLightImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
          }
        />
      )}

      {/* video tour */}
      <PlayVideo
        isOpen={propertyVideo}
        stopPlay={setPropertyVideo}
        videoId=""
        isCloseFunction={setPropertyVideo}
        videourl={propertyVideoUrl}
      />
    </>
  )
}

export default PropertyBanner
