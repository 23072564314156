import { Link } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import { Container } from "react-bootstrap"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { capitalize, numberFormat } from "../Common/utils"
import MortgageCalculator from "../MortgageCalculator/MortgageCalculator"
import PropertyAreaCards from "../PropertyAreaCard/PropertyAreaCards"
import PropertyContactModule from "../PropertyContactModule/PropertyContactModule"
import Map from "../PropertyMap/Map"
import SimilarSoldProperties from "../SimilarSoldProperties/SimilarSoldProperties"
import "./PropertyInformation.scss"

const PropertyInformation = props => {
  const propertyInfo = props.PageData
  const { isLargeScreen, isMobile } = useDeviceMedia()
  let mortgageValue = ""
  if (typeof window !== "undefined") {
    mortgageValue = sessionStorage.getItem("mortgage_repayments")
  }

  const negotiator = props.negotiator
  const negoImage = props.negoImage
  const qrImage =
    propertyInfo?.qr_code?.length > 0
      ? propertyInfo?.qr_code[0]?.validationQr
      : ""
  const permitNumber =
    propertyInfo?.qr_code?.length > 0
      ? propertyInfo?.qr_code[0]?.listingNumber
      : ""

  return (
    <div>
      <div className="property-info-wrapper">
        <Container>
          <div className="property-info">
            <div className="property-left-info">
              <p className="property-price">
                AED {numberFormat(propertyInfo?.price)}{" "}
                {propertyInfo?.search_type === "lettings"
                  ? propertyInfo?.price_qualifier?.toUpperCase()
                  : ""}
              </p>
              {propertyInfo?.search_type !== "lettings" && (
                <div className="estimate-mortgage-text">
                  <p>Estimated Mortgage AED {mortgageValue}/month.</p>
                  <Link to="/other-services/mortgages/">Get pre-approved</Link>
                </div>
              )}
              <h1 className="title">
                {capitalize(propertyInfo?.title)} in {propertyInfo?.display_address}
              </h1>
              <div className="rooms-count">
                {propertyInfo?.bedroom !== 0 && (
                  <div className="bedrooms">
                    <i className="icon bed-icon-grey" />{" "}
                    <span>
                      {propertyInfo?.bedroom}
                      {!isMobile &&
                        `${propertyInfo?.bedroom > 1 ? " Beds" : " Bed"}`}
                    </span>
                  </div>
                )}
                {propertyInfo?.bathroom !== 0 && (
                  <div className="bathrooms">
                    <i className="icon bath-icon-grey" />{" "}
                    <span>
                      {propertyInfo?.bathroom}
                      {!isMobile &&
                        `${propertyInfo?.bathroom > 1 ? " Baths" : " Bath"}`}
                    </span>
                  </div>
                )}
                {propertyInfo?.floorarea_min &&
                  propertyInfo?.floorarea_type === "squareFeet" && (
                    <div className="area">
                      <i className="icon area-icon-grey" />{" "}
                      <span>
                        {numberFormat(propertyInfo?.floorarea_min)} sq.ft
                      </span>
                    </div>
                  )}
              </div>
              {
                propertyInfo?.long_description && (
                <div className="description-block">
                  {/* {propertyInfo?.description && (
                    <div className="short-description">
                      {parse(propertyInfo?.description)}
                    </div>
                  )} */}

                  {propertyInfo?.long_description && (
                    <div className="property-long-description">
                      {parse(propertyInfo?.long_description)}
                    </div>
                  )}
                </div>
              )}
              {propertyInfo?.accommodation_summary?.length > 0 && (
                <>
                  <div className="property-horizontal-line"></div>
                  <div className="features-details">
                    <h5>Features</h5>
                    <ul className="feature-list-items">
                      {propertyInfo?.accommodation_summary.map(feature => {
                        return <li className="feature-list">{feature}</li>
                      })}
                      <li className="feature-list">
                        Property reference code: {propertyInfo?.crm_id}
                      </li>
                    </ul>
                  </div>
                </>
              )}
              {propertyInfo?.special?.length > 0 && (
                <>
                  <div className="property-horizontal-line"></div>
                  <div className="amenities-details">
                    <h5>Amenities</h5>
                    <ul className="amenities-list-items">
                      {propertyInfo?.special?.map(amenities => {
                        return (
                          <div className="aminities-contents">
                            <icon className="icon tick-icon"></icon>
                            <li className="feature-list">{amenities}</li>
                          </div>
                        )
                      })}
                    </ul>
                  </div>
                </>
              )}

              {isLargeScreen && (negotiator || props.image_url) && (
                <>
                  <div className="property-horizontal-line"></div>
                  <div className="property-team-data">
                    <div className="image-section">
                      <img src={props.image_url} alt="team-image" />
                    </div>
                    <div className="property-team-info">
                      {props.name && <h5 className="name">{props.name}</h5>}
                      {negotiator.designation && (
                        <p className="designation">{negotiator.designation}</p>
                      )}
                      {negotiator?.language_speaks && (
                        <p className="language-speak">
                          Speaks {negotiator?.language_speaks}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}

              {propertyInfo?.latitude && propertyInfo?.longitude ? (
                <>
                  <div className="property-horizontal-line"></div>
                  <div className="property-map-component">
                    <h5>Location</h5>
                    <p className="address_map_property">
                      {propertyInfo.display_address}
                    </p>
                    {/* <Map property_details_map_data={propertyInfo} /> */}
                  </div>
                </>
              ) : null}

              {propertyInfo.department === "residential" &&
                propertyInfo.search_type === "sales" && (
                  <SimilarSoldProperties propertyInfo={propertyInfo} />
                )}

              {propertyInfo.search_type === "sales" && (
                <MortgageCalculator propertyInfo={propertyInfo} />
              )}

              {qrImage && (
                <>
                  <div className="property-horizontal-line"></div>
                  <div className="qr-code-module">
                    <div className="image-section">
                      <img src={qrImage} alt="qr code" />
                    </div>
                    <div className="qr-content">

                    <div className="icon-text-holder">
                      <h5>DLD Permit Number:</h5>
                        
                          <div 
                          className="info-icon-module">
                            <i className="icon info-icon"/>
                            <span className="tri-icon"><i className="icon triangle-icon" /></span>
                            <div className={`show-when-hover`}>
                            
                              <p>
                              The Dubai Land Department strongly advises all customers and 
                              investors to only engage with real estate advertisements that 
                              feature the QR Code
                              </p>
                            </div>
                          </div>
                    </div>

                      
                      
                      <p>{permitNumber}</p>
                      <p className="qr-text">
                        This property listing has been reviewed and verified by Dubai Land Department
                      </p>
                    </div>
                  </div>
                </>
              )}

              <div className="property-horizontal-line"></div>
              <PropertyAreaCards propertyInfo={propertyInfo} />
            </div>
            {!isLargeScreen && (
              <div className="property-right-info">
                <PropertyContactModule
                  negotiator={negotiator}
                  negoImage={negoImage}
                  propertyInfo={propertyInfo}
                  image_url={props.image_url}
                  name={props.name}
                  phoneNum={props.phoneNum}
                />
              </div>
            )}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default PropertyInformation
